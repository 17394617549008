document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll("[role='tab']").forEach((tab) => {
    tab.addEventListener("click", function () {
      document
        .querySelectorAll("[role='tab']")
        .forEach((t) => t.setAttribute("aria-selected", "false"));
      this.setAttribute("aria-selected", "true");
      
      localStorage.setItem("selectedTabId", this.id);
    });
  });
  
  // Recuperar e ativar a aba armazenada na carga da página
  const selectedTabId = localStorage.getItem('selectedTabId');
  if (selectedTabId) {
    document.querySelectorAll("[role='tab']").forEach(tab => {
      tab.setAttribute('aria-selected', 'false');
    });
    
    const selectedTab = document.getElementById(selectedTabId);
    if (selectedTab) {
      selectedTab.setAttribute('aria-selected', 'true');
    }
  }
  
  // Configura a requisição AJAX para enviar os dados.
  /*
  var xhr = new XMLHttpRequest();

  xhr.open("POST", "/aiservice", true);
  xhr.setRequestHeader("X-CSRF-TOKEN", csrfToken);

  xhr.onload = function () {
    // Trata a resposta do servidor.
    if (xhr.status >= 200 && xhr.status < 300) {
      // Sucesso na requisição.
      showMessage("Resposta", xhr.responseText);
      document.querySelectorAll(".ability-select").forEach((checkbox) => {
        checkbox.checked = false;
      });
    } else {
      // Trata os erros da requisição.
      console.error("Erro ao enviar os dados:", xhr.statusText);
    }
  }; */
  
  document.addEventListener("click", (event) => {
    if (event.target.matches(".select-all-abilities")) {
      document.querySelectorAll(".ability-select").forEach((checkbox) => {
        checkbox.checked = event.target.checked;
      });
    } else if (event.target.id === "create-ai-service") {
      const formData = new FormData();
      
      const csrfToken = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content");
      
      document
        .querySelectorAll(".ability-select:checked")
        .forEach(function (checkbox) {
          const container = checkbox.closest(".tab-content");
          if (
            container &&
            container.style.display !== "none" &&
            !container.classList.contains("hidden")
          ) {
            formData.append(checkbox.name, checkbox.value);
          } else {
            console.log("Elemento não visível sendo ignorado");
          }
        });
      
      const xhr = new XMLHttpRequest();
      
      xhr.open("POST", "/aiservice", true);
      xhr.setRequestHeader("X-CSRF-TOKEN", csrfToken);
      
      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          showError("Resposta", xhr.responseText);
          document.querySelectorAll(".ability-select").forEach((checkbox) => {
            checkbox.checked = false;
          });
        } else {
          console.error("Erro ao enviar os dados:", xhr.statusText);
        }
      };
      
      xhr.onerror = function () {
        console.error("Erro de rede.");
      };
      
      xhr.send(formData);
    }
  });
});
import "./bootstrap";
import "flowbite";
import hljs from "highlight.js";
import "highlight.js/styles/base16/macintosh.css";
import { fabric } from "fabric";
import Sortable from "sortablejs";
import "./abilities-list";
import Fuse from "fuse.js";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

import Alpine from "alpinejs";
import QRCode from "qrcode";

window.Alpine = Alpine;
window.hljs = hljs;
window.fabric = fabric;
window.Sortable = Sortable;
window.Fuse = Fuse;
window.FingerprintJS = FingerprintJS;
window.QRCode = QRCode;

Alpine.start();

document.querySelectorAll(".copyButton").forEach((button) => {
  button.addEventListener("click", function () {
    const text = this.getAttribute("data-clipboard-text");
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    try {
      const successful = document.execCommand("copy");
      const msg = successful ? "sucesso" : "falha";
      console.log("Fallback: Texto copiado com " + msg);
      // Opcional: Exibir notificação de sucesso/falha
      alert("Texto copiado!");
    } catch (err) {
      console.error("Fallback: Erro ao copiar texto", err);
    }
    document.body.removeChild(textarea);
  });
});

document.addEventListener("DOMContentLoaded", () => {
  // Hightlight.js
  document.querySelectorAll(".language-json").forEach((element) => {
    hljs.highlightElement(element);
  });

  // Inicializa o FingerprintJS
  const fpPromise = FingerprintJS.load();

  // Função para gerar o fingerprint
  async function getFingerprint() {
    const fp = await fpPromise;
    const result = await fp.get();

    // Obtém o identificador único do dispositivo
    const visitorId = result.visitorId;
    console.log(`Visitor ID: ${visitorId}`);

    // Criar o QRCode
    const qrcodeElement = document.getElementById("qrcodeCanvas");
    if (qrcodeElement) {
      const url = `${window.location.origin}/vid/${visitorId}`;

      QRCode.toCanvas(qrcodeElement, url, {
        width: 150,
        height: 150,
      });

      const lastSix = visitorId.slice(-6);

      // Seleciona os elementos de div dentro do contêiner
      const visitorIdElements = document.querySelectorAll(".visitorId > div");

      // Preenche os elementos com os caracteres correspondentes
      visitorIdElements[0].textContent = lastSix[0];
      visitorIdElements[1].textContent = lastSix[1];
      visitorIdElements[2].textContent = lastSix[2];
      visitorIdElements[4].textContent = lastSix[3];
      visitorIdElements[5].textContent = lastSix[4];
      visitorIdElements[6].textContent = lastSix[5];
    }
  }

  // Chama a função para obter o fingerprint
  getFingerprint();
});

window.findCircular = (obj, path = [], seen = new Map()) => {
  if (typeof obj !== "object" || obj === null) {
    return null;
  }

  if (seen.has(obj)) {
    return {
      path: seen.get(obj),
      key: path[path.length - 1],
      circularReference: path.join(" -> "),
    };
  }

  seen.set(obj, [...path]);

  for (const [key, value] of Object.entries(obj)) {
    const newPath = [...path, key];
    const result = findCircular(value, newPath, seen);
    if (result) {
      return result;
    }
  }

  return null; // Retorna nulo se não encontrar nenhuma referência circular
};
